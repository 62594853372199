import { motion } from "framer-motion";
import { fadeIn } from "../utils/motion";

const ExploreCard = ({ id, imgUrl, title, description, index, active, handleClick }) => (
    <motion.div
        variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
        className={`relative ${active === id ? 'lg:flex-[3.5] flex-[10]' : 'lg:flex-[0.5] flex-[2]'} 
    flex items-center justify-center min-w-[170px] h-[700px] transition-[flex] duration-[0.7s] ease-out-flex cursor-pointer`}
        onClick={() => handleClick(id)}>
        <img src={imgUrl} alt="title" className="absolute w-full h-full object-cover rounded-[24px]" />
        {active !== id ? (
            <>
                <h3 className="font-semibold max-lg:text-[16px] text-[18px] text-white absolute z-0 lg:bottom-20 lg:rotate-[-90deg] lg:origin-[0,0]]">
                    {title}
                </h3>
            </>
        ) : (
            <div className="absolute bottom-0 p-8 max-lg:p-0 justify-start w-full flex-col bg-[rgba(0,0,0,0.5)] rounded-b-[24px]">
                <h2 className="mb-[24px] max-lg:mb-0 font-semibold max-lg:text-[16px] text-[24px] text-white">
                    {title}
                </h2>
                <p className="font-normal max-lg:text-[8px] max-lg:pb-2 text-sm leading-[20px] max-lg:leading-3 text-white uppercase">
                    {description}
                </p>
            </div>
        )}
    </motion.div>
);

export default ExploreCard;