import { motion } from "framer-motion";
import { inicio } from "../constants/index.js";
// import { TitleText } from "./CustomText.jsx";
 



function Cards() {
    return (
        <div id="whoare" className="flex max-lg:flex-col gap-6 w-full max-lg:items-center justify-center  py-8 ">
            {inicio.map((persona, index) => (
                <motion.div
                    initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        x: index % 2 === 0 ? 50 : -50
                    }}
                    whileInView={{
                        opacity: 1,
                        x: 0, // Slide in to its original position
                        transition: {
                            duration: 1 // Animation duration
                        }
                    }}
                    viewport={{ once: true }}
                    key={index}
                    className="w-[40%] max-lg:w-[90%] max-lg:flex-col max-lg:items-center max-lg:pt-10 rounded overflow-hidden shadow-2xl flex ">

                    <div className="w-[40%]">
                        <img
                            className="w-full h-80 object-contain"
                            src={persona.img}
                            alt={`${persona.name}-picture`}
                        />
                        <div className="px-6 py-4 text-center">
                            <div className="noto-serif-custom-title  text-lg mb-2">
                                {persona.name}
                            </div>

                            <div className="noto-serif-custom text-base text-blue-gray-700">
                                {persona.role}
                            </div>
                        </div>
                    </div>
                    <div className="w-[60%] max-lg:w-full">
                        <p className="mt-2 text-gray-700 max-lg:pb-4 text-justify font-semibold px-5">
                            {persona.description}
                        </p>
                    </div>
                </motion.div>
            ))}
        </div>

    );

}

export default Cards;
