import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import { motion } from 'framer-motion'; // Importa motion

const TeamCard = ({ team }) => {
    return (
        <div className="flex justify-center items-center min-h-[90vh]">
            <div className="flex flex-wrap justify-center w-[80%]"> {/* Cambiado aquí */}
                {team.map(person => (
                    <div key={person.id} className="p-4 w-1/3 max-lg:w-full"> {/* Ajustado el ancho aquí */}
                        <motion.div
                            className="relative max-lg:flex max-lg:items-center max-lg:justify-center max-lg:bg-transparent bg-white shadow-lg rounded-lg overflow-hidden w-full"
                            whileHover={{ scale: 1.1 }} // Efecto de hover para escalar
                            transition={{ duration: 0.3 }} // Duración de la transición
                        >
                            {/* Imagen */}
                            <img 
                                src={person.img} 
                                alt={person.name} 
                                className="w-full h-[25rem] max-lg:object-fill max-lg:w-auto object-cover transition-transform duration-300 ease-in-out hover:scale-105"
                            />
                            
                            {/* Contenedor de hover con caption */}
                            <div className="p-2 absolute inset-0 bg-blue-700 bg-opacity-40 text-white flex flex-col justify-center items-center opacity-0 hover:opacity-100 transition-opacity duration-300">
                                <h3 className="text-lg font-semibold text-center border-solid border-2 border-white p-1">{person.name}</h3>
                                <p className="text-[0.8rem] text-center mt-2" style={{ whiteSpace: 'pre-line', lineHeight: '1' }}>{person.caption}</p>
                            </div>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeamCard;
