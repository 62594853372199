import React from 'react'

const Footer = () => {
  return (<footer
    className="bg-neutral-100 text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left mt-10">
    <div
      className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-neutral-500 lg:justify-between">
      <div className="mr-12 hidden lg:block">
        <span>Siguenos en nuestras redes sociales:</span>
      </div>
      {/* <!-- Social network icons container --> */}
      <div className="flex justify-center">
        <a href="https://www.facebook.com/moises.agudeloayala/?locale=es_LA&_rdc=2&_rdr"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-neutral-600 dark:text-neutral-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
              d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
          </svg>
        </a>
        <a href="https://www.instagram.com/moisesagudeloayala/?hl=es"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-neutral-600 dark:text-neutral-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
              d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
          </svg>
        </a>
        <a href="https://open.spotify.com/show/5vYFzC6OubQ9HBhOHmRt7k?si=pabbGePeSTOZ2FNJYVih6g&nd=1"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 text-neutral-600 dark:text-neutral-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="currentColor"
            viewBox="0 0 24 24">
            <path
              d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm5.449 17.336c-.213.35-.666.46-1.021.247-2.799-1.714-6.325-2.1-10.523-1.145-.403.094-.802-.166-.896-.568-.093-.403.167-.802.57-.895 4.614-1.063 8.497-.632 11.623 1.297.352.213.463.666.247 1.02zm1.496-3.033c-.266.428-.832.562-1.261.297-3.201-1.964-8.09-2.532-11.874-1.379-.487.152-1.007-.111-1.158-.599-.152-.487.11-1.006.598-1.158 4.267-1.334 9.595-.697 13.285 1.617.428.266.563.832.297 1.262zm.161-3.211c-3.904-2.343-10.396-2.561-14.186-1.396-.595.185-1.23-.154-1.414-.75-.185-.595.153-1.23.749-1.414 4.36-1.36 11.456-1.11 15.849 1.636.548.329.725 1.042.396 1.591-.328.549-1.043.725-1.594.333z" />
          </svg>
        </a>

      </div>
    </div>

    {/* <!-- Main container div: holds the entire content of the footer, including four sections (TW Elements, Products, Useful links, and Contact), with responsive styling and appropriate padding/margins. --> */}
    <div className="mx-6 py-10 text-center md:text-left">
      <div className="flex justify-start w-full">


        {/* <!-- Contact section --> */}
        <div className='w-full'>
          <h6
            className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
            Contactenos
          </h6>
          <p className="mb-4 flex items-center justify-center md:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5">
              <path
                d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
              <path
                d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
            </svg>
            Tuluá, Valle del Cauca, CO.
          </p>
          <p className="mb-4 flex items-center justify-center md:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5">
              <path
                d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
              <path
                d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
            </svg>
            myabogados@hotmail.com          </p>
          <p className="mb-4 flex items-center justify-center md:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5">
              <path
                fillRule="evenodd"
                d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clipRule="evenodd" />
            </svg>

            (602) 225-0073 - +57 (310) 505-1279 <br />
            +57 (315) 601 7367
          </p>
          <p className="flex items-center justify-center md:justify-start">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="mr-3 h-5 w-5">
              <path
                fillRule="evenodd"
                d="M12 2C8.134 2 5 5.134 5 9c0 5.25 5.25 11 6.292 12.165a.75.75 0 001.416 0C13.75 20 19 14.25 19 9c0-3.866-3.134-7-7-7zm0 9.75A2.75 2.75 0 1112 6a2.75 2.75 0 010 5.5z"
                clipRule="evenodd" />
            </svg>

            Cra 27. # 27 - 24 Oficina 402 B<br />
            Centro Comercial Café Plaza
          </p>
        </div>
      </div>
    </div>

    {/* <!--Copyright section--> */}
    <div className="bg-neutral-200 p-6 text-center dark:bg-neutral-700">
      <span>2024 © J&MSolutions. All rights reserved.</span>

    </div>
  </footer>
  )
}

export default Footer