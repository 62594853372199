import { motion } from 'framer-motion'; // Asegúrate de tener framer-motion instalado
import { useState, useRef, useEffect } from 'react'; // Importamos hooks para manejar la visibilidad
import { team2 } from '../constants';
import { TitleText } from './CustomText';
import TeamCard from './TeamCard';
import bgImg from '../asset/bg9.jpeg';
import { slideIn } from '../utils/motion';

const OurTeam = () => {
    const [inView, setInView] = useState(false); // Estado para saber si está en vista
    const sectionRef = useRef(null); // Referencia al contenedor de la animación

    // Utilizamos useEffect para observar el contenedor cuando entra en vista
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true); // Si el contenedor entra en vista, activamos la animación
                }
            },
            { threshold: 0.5 } // El 50% del contenedor debe estar visible para activar la animación
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current); // Observamos la sección referenciada
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current); // Cleanup del observer
            }
        };
    }, []);

    return (
        <section
            style={{ backgroundImage: `url(${bgImg})` }}
            id='team'
            ref={sectionRef}
            className="relative bg-no-repeat bg-cover bg-center flex flex-col items-center"
        >
            <div className="absolute inset-0 bg-black opacity-60 z-0"></div>
            <TitleText title="Nuestro Equipo" textStyles="text-center text-3xl py-8 text-white" />
            <motion.div
                variants={slideIn('left', 'tween', 0.5, 1)}
                initial="hidden" 
                animate={inView ? 'show' : 'hidden'}
                className="w-full md:w-3/4 lg:w-2/3"
            >
                <TeamCard team={team2} />
            </motion.div>
        </section>
    );
}

export default OurTeam;
