import React from 'react'

const Loading = () => {
    return (
        <div className="flex items-center justify-center">
            <img
                src="/assets/logo2.png"
                alt="Loading"
                className="animate-ping transform-gpu opacity-animation w-80 h-auto"
            />
        </div>
    );
};

export default Loading