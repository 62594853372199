import './App.css';
import AboutUs from './components/AboutUs';
import Cards from './components/Cards';
import Carousel from './components/Carousel';
import Navbar from './components/Navbar';
import OurTeam from './components/OurTeam';
import PracticeAreas from './components/PracticeAreas';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Blog from './components/Blog';
import CompleteBlog from './components/CompleteBlog';
import Footer from './components/Footer';
import Contactanos from './components/Contactanos';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Carousel />
                <Blog />
                <Cards />
                <AboutUs />
                <PracticeAreas />
                <OurTeam />
                <Contactanos />
                <Footer />
              </>
            }
          />
          <Route path="/blogs" element={<CompleteBlog />} />
        </Routes>
      </div>

    </BrowserRouter>
  );
}

export default App;
