import { useState } from "react";
import { areas } from "../constants";
import { staggerContainer } from "../utils/motion";
import { TitleText} from "./CustomText";
import ExploreCard from "./ExploreCard";
import { motion } from "framer-motion";

const PracticeAreas = () => {

    const [active, setActive] = useState('area-2');

    return (
        <section className={`sm:p-16 xs:p-8 px-6 py-12`} id='explore'>
            <motion.div
                variants={staggerContainer}
                initial='hidden' whileInView='show'
                viewport={{ once: false, amount: 0.10 }}
                className={`2xl:max-w-[1280px] w-full mx-auto flex flex-col`}>
                <TitleText title={<>Áreas de Práctica</>} textStyles='text-center text-3xl' />
                <div className='mt-[50px] flex lg:flex-row flex-col min-h-[70vh] gap-5'>
                    {areas.map((area, index) => (
                        <ExploreCard key={area.id} {...area} index={index} active={active} handleClick={setActive} />
                    ))}
                </div>
            </motion.div>
        </section>
    )
}

export default PracticeAreas