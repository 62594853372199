'use client';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import { navVariants } from '../utils/motion';
import { TypingText } from './CustomText';


const Carousel = () => {
    return (
        <section className='w-full h-auto relative z-0' id="home">
            <motion.div
                variants={navVariants}
                initial='hidden'
                whileInView='show'
            >
                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-bold z-10 border-black'>
                    <h1 className="text-6xl max-lg:text-xl">M&ABOGADOS</h1>
                    <h2 className="text-3xl mt-2 max-lg:text-lg max-lg:mt-1">Moisés Agudelo</h2>
                    <div className="w-64 h-1 bg-white mx-auto mt-2 max-lg:w-40"></div>
                    <TypingText title='Usted tiene derecho' textStyles="text-center max-lg:text-xl" />
                </div>
                <div className="relative">
                    <Swiper
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        className="mySwiper"
                        modules={[Autoplay]}
                        loop={true}
                    >
                        <SwiperSlide>
                            <div className="relative">
                                <img src="/assets/Fondo1.jpeg" alt="carousel" className='h-[40rem] w-full object-cover max-lg:object-fill max-lg:h-auto' />
                                {/* Overlay oscuro */}
                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="relative">
                                <img src="/assets/Fondo2.jpeg" alt="carousel" className='h-[40rem] w-full object-cover max-lg:object-fill max-lg:h-auto' />
                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="relative">
                                <img src="/assets/Fondo3.jpeg" alt="carousel" className='h-[40rem] w-full object-cover max-lg:object-fill max-lg:h-auto' />
                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="relative">
                                <img src="/assets/Fondo4.jpeg" alt="carousel" className='h-[40rem] w-full object-cover max-lg:object-fill max-lg:h-auto' />
                                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-60"></div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

            </motion.div>
        </section>
    )
}

export default Carousel;
