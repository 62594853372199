import { motion } from 'framer-motion'
import { staggerContainer, fadeIn, planetVariants } from '../utils/motion'
import { aboutus } from '../constants'
import background from '../asset/bg6.jpg'
import { TitleText, FadeText, NormalText } from './CustomText'

const AboutUs = () => {
    return (

        <section
            style={{ backgroundImage: `url(${background})` }}
            className="relative bg-no-repeat bg-cover bg-center flex flex-col items-center"
            id='aboutus'
        >
            <div className="absolute inset-0 bg-black opacity-60 z-0"></div>
            <div className="relative z-10">
                <motion.div initial="hidden" whileInView="show" className="pt-5 text-center max-w-4xl w-full">
                    <TitleText title="Sobre Nosotros" textStyles="text-white md:text-2xl text-xl" />
                    <FadeText title='“La paz es el resultado de una justicia pronta y oportuna”' textStyles="underline text-white" />
                </motion.div>

                <div className="px-20 pt-8 max-w-4xl w-full ">
                    <motion.div
                        variants={staggerContainer}
                        initial='hidden'
                        whileInView='show'
                        viewport={{ once: 'false', amount: 0.25 }}
                        className="flex lg:flex-row flex-col gap-8 items-center"
                    >
                        <div className="overflow-hidden">

                            <motion.div
                                variants={planetVariants('left')}
                                className="flex justify-center "

                            >
                                <img
                                    src='/assets/circular_aboutus3.png'
                                    alt='about-us'
                                    className=' w-[210.5rem] h-[18rem] object-cover border-solid border-4 border-white rounded-full'
                                />
                            </motion.div>
                        </div>

                        <motion.div
                            variants={fadeIn('left', 'tween', 0.2, 0.1)}
                            className="flex justify-center flex-col p-8 max-lg:p-0"
                        >
                            <NormalText title={aboutus[0].text1} textStyles="text-center text-justify text-white w-full" />
                        </motion.div>
                    </motion.div>
                </div>

                <div className="px-20 pt-8 max-w-4xl w-full pb-8">
                    <motion.div
                        variants={staggerContainer}
                        initial='hidden'
                        whileInView='show'
                        viewport={{ once: 'false', amount: 0.25 }}
                        className="flex lg:flex-row flex-col gap-8 items-center"
                    >
                        <motion.div
                            variants={fadeIn('right', 'tween', 0.2, 0.1)}
                            className=" flex justify-center flex-col "
                        >
                            <NormalText title={aboutus[1].text2} textStyles="text-center text-justify text-white" />
                        </motion.div>

                        <div className="overflow-hidden">
                            <motion.div
                                variants={planetVariants('right')}
                                className="flex justify-center "
                        
                            >
                                <img
                                    src='/assets/circular_aboutus4.png'
                                    alt='about-us'
                                    className='w-[210.5rem] h-[17.5rem] object-cover border-solid border-4 border-white rounded-full'
                                />
                            </motion.div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>


    )
}

export default AboutUs