import { TitleText } from './CustomText';
import contacto from '../asset/ContactoMoisesAgudelo.jpeg';

const Contactanos = () => {
  return (
    <div id='contactanos' className='flex flex-col items-center max-lg:h-auto h-[70vh]'>
      <TitleText title="Contactanos" textStyles={"text-center text-3xl py-5"} />
      <div className='flex max-lg:flex-col max-lg:items-center w-full max-lg:gap-4 gap-32'>
        <div className='w-1/2 flex justify-end max-lg:w-full max-lg:justify-center'>
          <div className="group text-center bg-white/50 shadow-xl p-3 rounded-xl">
            <img src={contacto} alt="Moises Agudelo"
              className="w-[16rem] mx-auto img-shadow 
                            group-hover:scale-95 
                            group-hover:translate-y-[-50px] 
                            group-hover:translate-x-10 
                            group-hover:rotate-[20deg] 
                            transition-all duration-400"/>
            <div>
              <button
                className="btn-primary group-hover:mb-1 text-white font-semibold opacity-0 group-hover:opacity-100 bg-red-600 p-3 rounded-xl"
                onClick={() => window.open('https://wa.me/573105051279?text=Hola,%20quiero%20realizar%20una%20consulta', '_blank')}
              >
                Contactanos
              </button>
            </div>
          </div>
        </div>
        <div className='w-full max-lg:flex max-lg:items-center max-lg:justify-center lg:w-1/2 max-lg:pb-8 '>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.687265357794!2d-76.19908918916761!3d4.083949346677706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e39c5c6632ea663%3A0x6661d0b52cbcbbe6!2sCra.%2027%20%2327-24%2C%20Tulu%C3%A1%2C%20Valle%20del%20Cauca!5e0!3m2!1ses!2sco!4v1728332801097!5m2!1ses!2sco"
            className="w-[90%] h-[450px]"
            title="mapa de contacto"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </div>
  )
}

export default Contactanos