import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { navVariants } from '../utils/motion';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <motion.nav
      variants={navVariants}
      initial='hidden'
      whileInView='show'
      className={`fixed top-0 w-full lg:px-20 z-50 transition-colors duration-400 ${scrolled ? 'bg-black/60 mt-[-1rem] max-lg:mt-0' : 'bg-transparent'}`}
    >
      <div className="flex items-center justify-between max-lg:h-[4rem] h-[7rem]">
        <a href="#home">
          <img src="/assets/logo1.png" alt="logo" className='h-[10rem] mt-[-1rem] max-lg:h-[5rem]' />
        </a>
        <div className='hidden md:flex text-white gap-5'>
          <a href='#whoare' className='transition-colors hover:opacity-70'>Quienes Somos</a>
          <a href='#explore' className='transition-colors hover:opacity-70'>Areas de Practica</a>
          <a href='#team' className='transition-colors hover:opacity-70'>Abogados</a>
          <a href='#contactanos' className='transition-colors hover:opacity-70'>Contacto</a>
        </div>
        <div className="max-lg:block hidden">
          <button onClick={toggleMenu} className="text-white">
            {!isOpen && (
              <svg className="h-6 w-6 mr-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
            )}
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="max-lg:block bg-black opacity-95 absolute top-0 left-0 w-full h-screen flex flex-col z-50 space-y-6">
          <button onClick={toggleMenu} className="absolute top-4 right-4 text-white">
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className='flex flex-col text-white gap-5 py-14'>
            <a href='#quienessomos' className='transition-colors hover:opacity-70 text-white text-2xl'>Quienes Somos</a>
            <a href='#explore' className='transition-colors hover:opacity-70 text-white text-2xl'>Areas de Practica</a>
            <a href='#team' className='transition-colors hover:opacity-70 text-white text-2xl'>Abogados</a>
            <a href='#contactanos' className='transition-colors hover:opacity-70 text-white text-2xl'>Contacto</a>
            <img src="/assets/blancoynegronav.png" alt="nav" />
          </div>
        </div>
      )}
    </motion.nav>
  );
};

export default Navbar;
